"use client";

import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  useTransition,
} from "react";
import "./bottom-bar.css";
import Link from "next/link";
import { SelectDate } from "@/components/user-data-inputs/select-date";
import { AddGuests } from "@/components/user-data-inputs/add-guests";
import { format } from "date-fns";
import { useRouter, useSearchParams } from "next/navigation";
import { toast } from "react-hot-toast";
import { COUPON_TYPES, Guests, SearchDataType } from "@/types/common";
import {
  addSearchDataInLocalStorage,
  clearSearchData,
} from "@/lib/search-data";
import useLocalStorageSearchData from "@/hooks/use-local-storage-search-data";
import { SubmitButton } from "./submit-button";
import { BodyText } from "@/components/common/texts/body-text";
import { LabelText } from "@/components/common/texts/label-text";
import { HeadlineText } from "@/components/common/texts/headline-text";
import { checkLogin } from "@/lib/check-login";
import { RoomType } from "@/types/room_type";
import * as gtag from "./../../../../../lib/gtag";
import { MobileEditIcon } from "../../../../../assets/mobile-edit-icon";
import {
  DisplayPrices,
  DisplayTotalPrices,
  ExploreNow,
  FullyBooked,
  Offer,
} from "../common";
import { MobileBottomBarCloseIcon } from "../../../../../assets/mobile-bottom-bar-close-icon";
import {
  BookingSummaryNotSelectedText,
  BookingSummarySelectedText,
  InputText,
} from "@/components/user-data-inputs/booking-summary-texts";
import { AppRouterInstance } from "next/dist/shared/lib/app-router-context.shared-runtime";
import { LongStayOffer } from "../long-stay-offer";
import { useUpdateRooms } from "@/store/update-rooms";
import { useUpdatePrices } from "@/store/update-prices";
import { useUpdatePropertyCoupons } from "@/store/update-property-coupons";

interface Props {
  propertyId: string | undefined;
  roomIds: string[];
  maxOccupancy: number | undefined;
  roomNames: string[];
  propertyName: string;
  bookedDates: undefined | Date[];
  searchData: SearchDataType;
  setSearchData: Dispatch<SetStateAction<SearchDataType>>;
  id: string;
  rooms: RoomType[];
  isFullVilla: boolean;
  baseOccupancy: number;
  city: string;
  villaId: string;
}

export const BottomBar = ({
  roomIds,
  propertyId,
  maxOccupancy,
  roomNames,
  propertyName,
  bookedDates,
  searchData,
  setSearchData,
  id,
  isFullVilla,
  baseOccupancy,
  city,
  villaId,
}: Props) => {
  const { isBooked: isVillaBooked } = useUpdateRooms((state) => state);
  const [isDatesModalOpen, setIsDatesModalOpen] = useState(false);
  const [isGuestsOpen, setIsGuestsOpen] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    defaultPropertyPrice,
    price: { pricePerNight, finalPrice },
  } = useUpdatePrices((state) => state);

  const router = useRouter();
  const searchParams = useSearchParams();
  const isBooked = searchParams.get("booked");
  const modalToDisplay = searchParams.get("mobileBookingDetails");
  const [isPending, startTransition] = useTransition();
  const [isSubmitPending, startSubmitTransition] = useTransition();
  const { cutThroughPrice, cutThroughPricePerNight } = useUpdatePrices(
    (state) => state
  );

  const isDates = useMemo(
    () => searchData.checkInDate && searchData.checkOutDate,
    [searchData.checkInDate, searchData.checkOutDate]
  );

  const msPerDay = 24 * 60 * 60 * 1000;
  const dateDifference =
    //@ts-ignore
    (new Date(searchData.checkOutDate) - new Date(searchData.checkInDate)) /
    msPerDay;

  const handleDates = (date: [null | Date, null | Date]) => {
    const [startDate, endDate] = date;
    setSearchData((prev) => {
      return { ...prev, checkInDate: startDate, checkOutDate: endDate };
    });
    if (startDate && endDate) setIsGuestsOpen(true);
  };

  const handleGuests = useCallback(
    (guests: Guests) => {
      gtag.event({
        action: `guest_number_change_half`,
        category: "user input",
        label: "User trying to change guest number",
        value: 1,
      });
      setSearchData((prev) => {
        return { ...prev, guests };
      });
    },
    [setSearchData]
  );

  const clearAll = () => {
    clearSearchData({ setSearchData });
  };

  const handleBookNow = () => {
    startTransition(async () => {
      if (
        !searchData.guests.noOfTotalGuests &&
        (!searchData.checkInDate || !searchData.checkOutDate)
      ) {
        toast.error("Please select date and number of guest");
        router.push("?mobileBookingDetails=true");
        return;
      }

      if (!searchData.checkInDate || !searchData.checkOutDate) {
        toast.error("Please select your travel dates");
        router.push("?mobileBookingDetails=true");
        return;
      }

      if (!searchData.guests.noOfTotalGuests) {
        toast.error("Please select the number of guests");
        router.push("?mobileBookingDetails=true");
        return;
      }

      if (maxOccupancy && searchData.guests.noOfTotalGuests > maxOccupancy) {
        toast.error("You exceeded the maximum guests limit!");
        router.push("?mobileBookingDetails=true");
        return;
      }

      if (!roomIds || roomIds.length === 0) {
        toast.error("Please select a room");
        router.push("?mobileBookingDetails=true");
        return;
      }

      if (dateDifference <= 0) return;

      const now = new Date();

      const expiryTime = now.getTime() + 10 * 60 * 60 * 1000;
      const bookingData = {
        villaId: villaId,
        bookedRooms: roomIds,
        checkInDate: searchData.checkInDate.toDateString(),
        checkOutDate: searchData.checkOutDate.toDateString(),
        noOfPeople: searchData.guests.noOfTotalGuests,
        noOfChildren: searchData.guests.noOfChildren,
        noOfPets: searchData.guests.noOfPets,
        noOfAdults: searchData.guests.noOfAdults,
        price: pricePerNight,
        noOfDays: dateDifference,
        expiry: expiryTime,
        roomNames,
        propertyId,
        propertyName,
        id,
        isFullVilla,
        maxGuests: maxOccupancy,
        defaultPropertyPrice,
        baseOccupancy,
      };

      localStorage.removeItem("bookingData");
      localStorage.setItem("bookingData", JSON.stringify(bookingData));
      const isLoggedIn = await checkLogin();
      const eventName = `${id}_booknow`;
      gtag.event({
        action: eventName,
        category: "commercial_intent",
        label: "Book now pressed",
        value: 1,
      });
      if (!isLoggedIn) {
        router.push("/login?booking=true");
      } else {
        router.push("/guest-details");
      }
    });
  };

  const handleSubmit = () => {
    startSubmitTransition(() => {
      if (
        !searchData.checkInDate ||
        !searchData.checkOutDate ||
        searchData.guests.noOfTotalGuests === 0
      ) {
        toast.error("Fill all the details, please!");
        router.push("?mobileBookingDetails?=true");
        return;
      }

      addSearchDataInLocalStorage(searchData);

      const params = new URLSearchParams(searchParams);

      params.delete("mobileBookingDetails");

      router.replace(`?${params.toString()}`);
    });
  };

  useEffect(() => {
    window.innerWidth > 475 && router.push("/");
  }, [router]);

  useEffect(() => {
    if (modalToDisplay) {
      document.body.style.overflowY = "hidden";
    }
    return () => {
      document.body.style.overflowY = "auto";
    };
  }, [modalToDisplay]);

  // Add effect to handle body scroll when modal is open
  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflowY = "hidden";
    }
    return () => {
      document.body.style.overflowY = "auto";
    };
  }, [isModalOpen]);

  useLocalStorageSearchData({ setSearchData, maxOccupancy });

  useEffect(() => {
    if (isBooked) {
      setSearchData((prev) => ({
        ...prev,
        checkInDate: null,
        checkOutDate: null,
      }));
    }
  }, [isBooked, setSearchData]);

  return (
    <>
      {isModalOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-[48]"
          onClick={() => setIsModalOpen(false)}
        />
      )}

      <div
        ref={modalRef}
        className={`${modalToDisplay && "bottom-bar"} ${
          isModalOpen && "!p-0 !border-0 h-auto"
        } hidden mobile:block fixed z-[49] bottom-0 left-0 px-5 bg-white h-[80px] w-screen border-t border-secondaryG2`}
      >
        {modalToDisplay && (
          <div className="mt-24 h-full w-full relative py-[30px]">
            <div className="flex flex-col gap-y-3">
              <HeadlineText className="text-primaryB7 !normal-case mobile:!text-[25px]">
                Start booking your next stay
              </HeadlineText>

              <SelectDate
                checkOutDate={searchData.checkOutDate}
                checkInDate={searchData.checkInDate}
                handleDates={handleDates}
                bookedDays={bookedDates}
                isDatesOpen={isDatesModalOpen}
                setIsDatesOpen={setIsDatesModalOpen}
              >
                <LabelText className="text-primaryB7">booking dates?</LabelText>
              </SelectDate>

              <AddGuests
                guests={searchData.guests}
                handleGuests={handleGuests}
                maxGuests={maxOccupancy}
                isGuestsOpen={isGuestsOpen}
                setIsGuestsOpen={setIsGuestsOpen}
              >
                <LabelText className="text-primaryB7">
                  How many of you
                </LabelText>
              </AddGuests>
            </div>
          </div>
        )}

        {!isModalOpen && !modalToDisplay ? (
          <div className="fixed left-0 bottom-[80px] w-full">
            {isVillaBooked ? (
              <FullyBooked isModal={false} />
            ) : !isDates ? (
              <Offer isModal={false} isDates={false} />
            ) : (
              <Offer isModal={false} isDates={true} />
            )}
          </div>
        ) : null}

        {isModalOpen ? (
          isVillaBooked || !isDates ? (
            <CheckAvailabilityModal
              searchData={searchData}
              setIsModalOpen={setIsModalOpen}
              router={router}
              city={city}
              isBooked={isVillaBooked}
            />
          ) : isDates ? (
            <BookNowModal
              searchData={searchData}
              setIsModalOpen={setIsModalOpen}
              router={router}
              city={city}
              handleBookNow={handleBookNow}
              isPending={isPending}
            />
          ) : null
        ) : null}

        {!isModalOpen && (
          <div className="absolute bottom-4 left-1/2 -translate-x-1/2 w-full px-5 flex items-center justify-between">
            {!modalToDisplay && !isModalOpen ? (
              <div className="flex flex-col items-start gap-y-1.5">
                <Link href="#select-stay">
                  <DisplayPrices
                    cutThroughPrice={
                      isVillaBooked || !isDates
                        ? cutThroughPricePerNight
                        : cutThroughPrice
                    }
                    isPerNight={isVillaBooked || !isDates ? true : false}
                    price={
                      isVillaBooked || !isDates ? pricePerNight : finalPrice
                    }
                    isBottomBar
                  />
                </Link>
                <button
                  className="flex items-center gap-x-1"
                  onClick={() => setIsModalOpen(true)}
                >
                  {searchData.checkInDate &&
                  searchData.checkOutDate &&
                  searchData.guests.noOfTotalGuests ? (
                    <BodyText className="text-neutralN8 mobile:!leading-[14px] underline">{`${format(
                      searchData.checkInDate,
                      "dd MMM"
                    )} - ${format(searchData.checkOutDate, "dd MMM")} | ${
                      searchData.guests.noOfTotalGuests
                    } guests`}</BodyText>
                  ) : (
                    <LabelText className="text-neutralN7 mobile:!text-[12px] mobile:!font-light mobile:!leading-[12px] underline">
                      select dates | guests
                    </LabelText>
                  )}
                  <MobileEditIcon />
                </button>
              </div>
            ) : (
              <button onClick={clearAll}>
                <LabelText className="text-primaryB7 mobile:!font-normal mobile:!text-[14px]">
                  Clear filters
                </LabelText>
              </button>
            )}
            {!modalToDisplay ? (
              <div>
                {!searchData.checkInDate ||
                !searchData.checkOutDate ||
                searchData.guests.noOfTotalGuests === 0 ||
                isVillaBooked ? (
                  <div className="w-[160px]">
                    <SubmitButton
                      onSubmit={() =>
                        startTransition(() => {
                          router.push("?mobileBookingDetails=true");
                        })
                      }
                      isNotActive={false}
                      isPending={isPending}
                      text="Check Availability"
                    />
                  </div>
                ) : !finalPrice ? (
                  <div className="w-[150px]">
                    <SubmitButton
                      onSubmit={() =>
                        startTransition(() => {
                          router.push("#select-stay");
                        })
                      }
                      isNotActive={false}
                      isPending={isPending}
                      text="Select Stay"
                    />
                  </div>
                ) : (
                  <div className="w-[150px]">
                    <SubmitButton
                      onSubmit={handleBookNow}
                      isNotActive={false}
                      isPending={isPending}
                      text="Book Now"
                    />
                  </div>
                )}
              </div>
            ) : (
              <div className="w-[160px]">
                <SubmitButton
                  isPending={isSubmitPending}
                  text="done"
                  isNotActive={
                    !searchData.checkInDate ||
                    !searchData.checkOutDate ||
                    searchData.guests.noOfTotalGuests === 0
                  }
                  onSubmit={handleSubmit}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

interface ModalProps {
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  searchData: SearchDataType;
  router: AppRouterInstance;
  city: string;
  isBooked?: boolean;
  handleBookNow?: () => void;
  isPending?: boolean;
}

const CheckAvailabilityModal = ({
  setIsModalOpen,
  searchData,
  router,
  city,
  isBooked,
}: ModalProps) => {
  const { guests, checkInDate, checkOutDate } = searchData;
  const {
    price: { pricePerNight },
    cutThroughPricePerNight,
  } = useUpdatePrices((state) => state);

  const isCheckIn = checkInDate ? true : false;
  const isCheckOut = checkOutDate ? true : false;
  const isGuests = guests.noOfTotalGuests ? true : false;

  const exploreNow = () => {
    router.push(`/listings?city=${city}`);
    addSearchDataInLocalStorage({ ...searchData, city });
  };

  const redirectToMobileDisplay = () => {
    router.push("?mobileBookingDetails=true");
    setTimeout(() => {
      setIsModalOpen(false);
    }, 500);
  };

  return (
    <div className="h-full w-full">
      {isBooked ? <FullyBooked isModal /> : <Offer isModal isDates={false} />}
      <div className="w-full h-full py-[15px] px-5 flex flex-col gap-y-5">
        <div className="flex items-center justify-between">
          <DisplayPrices
            cutThroughPrice={cutThroughPricePerNight}
            price={pricePerNight}
            isPerNight
            isBottomBar
          />
          <ModalClose setIsModalOpen={setIsModalOpen} />
        </div>
        <div className="w-full flex flex-col gap-y-[5px]">
          <div className="w-full flex gap-x-[5px]">
            <InputValue
              redirectToMobileDisplay={redirectToMobileDisplay}
              input="check in"
              value={
                isCheckIn ? format(checkInDate!, "dd MMM, yyyy") : "add dates"
              }
              isValue={isCheckIn}
            />
            <InputValue
              redirectToMobileDisplay={redirectToMobileDisplay}
              input="check out"
              value={
                isCheckOut ? format(checkOutDate!, "dd MMM, yyyy") : "add dates"
              }
              isValue={isCheckOut}
            />
          </div>
          <InputValue
            redirectToMobileDisplay={redirectToMobileDisplay}
            input="number of guests"
            value={isGuests ? `${guests.noOfTotalGuests} guests` : "add guests"}
            isValue={isGuests}
          />
        </div>
        <SubmitButton
          onSubmit={redirectToMobileDisplay}
          isNotActive={false}
          isPending={false}
          text="Check Availability"
        />
        <ExploreNow exploreNow={exploreNow} />
      </div>
    </div>
  );
};

const BookNowModal = ({
  setIsModalOpen,
  searchData,
  router,
  handleBookNow,
  isPending,
}: ModalProps) => {
  const {
    price: { finalPrice },
    cutThroughPrice,
  } = useUpdatePrices((state) => state);
  const { guests, checkInDate, checkOutDate } = searchData;
  const { validPropertyCoupons } = useUpdatePropertyCoupons((state) => state);

  const isCheckIn = checkInDate ? true : false;
  const isCheckOut = checkOutDate ? true : false;
  const isGuests = guests.noOfTotalGuests ? true : false;

  const redirectToMobileDisplay = () => {
    router.push("?mobileBookingDetails=true");
    setTimeout(() => {
      setIsModalOpen(false);
    }, 500);
  };
  const coupon = validPropertyCoupons.find((item) => {
    if (item.code === "LONGSTAY") return item.code;
    else if (item.type === COUPON_TYPES.BASIC) return item;
  });

  if (!handleBookNow || isPending === undefined) return null;

  return (
    <div className="w-full">
      <Offer isDates={true} isModal />
      <div className="w-full h-full py-[15px] px-5 flex flex-col gap-y-5">
        <ModalClose setIsModalOpen={setIsModalOpen} />
        <div className="w-full flex flex-col gap-y-[5px]">
          <div className="w-full flex gap-x-[5px]">
            <InputValue
              redirectToMobileDisplay={redirectToMobileDisplay}
              input="check in"
              value={
                isCheckIn ? format(checkInDate!, "dd MMM, yyyy") : "add dates"
              }
              isValue={isCheckIn}
            />
            <InputValue
              redirectToMobileDisplay={redirectToMobileDisplay}
              input="check out"
              value={
                isCheckOut ? format(checkOutDate!, "dd MMM, yyyy") : "add dates"
              }
              isValue={isCheckOut}
            />
          </div>
          <InputValue
            redirectToMobileDisplay={redirectToMobileDisplay}
            input="number of guests"
            value={isGuests ? `${guests.noOfTotalGuests} guests` : "add guests"}
            isValue={isGuests}
          />
        </div>
        <LongStayOffer
          couponName={coupon?.code}
          description={coupon?.description}
          couponType={coupon?.type}
        />
        <div className="flex justify-between">
          <DisplayTotalPrices />
          <DisplayPrices
            cutThroughPrice={cutThroughPrice}
            isPerNight={false}
            price={finalPrice}
            isBottomBar
          />
        </div>
        <SubmitButton
          onSubmit={handleBookNow}
          isNotActive={false}
          isPending={isPending}
          text="Book Now"
        />
      </div>
    </div>
  );
};

const InputValue = ({
  input,
  value,
  isValue,
  redirectToMobileDisplay,
}: {
  input: string;
  value: string;
  isValue: boolean;
  redirectToMobileDisplay: () => void;
}) => {
  return (
    <button
      onClick={redirectToMobileDisplay}
      className="items-start text-left w-full flex flex-col gap-y-2 border-[0.5px] border-neutralN6 p-2.5 rounded-[2.5px]"
    >
      <InputText text={input} />
      {isValue ? (
        <BookingSummarySelectedText text={value} />
      ) : (
        <BookingSummaryNotSelectedText text={value} />
      )}
    </button>
  );
};

const ModalClose = ({
  setIsModalOpen,
}: {
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <button onClick={() => setIsModalOpen(false)} className="ml-auto">
      <MobileBottomBarCloseIcon />
    </button>
  );
};
