// /utils/dateUtils.ts

export function generateDateRange(
    checkIn: Date | null | undefined,
    checkOut: Date | null | undefined
): Date[] {
    console.log("got date range", checkIn, checkOut);
    if (!checkIn || !checkOut) {
        return [];
    }

    const dates: Date[] = [];
    const currentDate = new Date(checkIn);

    while (currentDate < checkOut) {
        dates.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
}
