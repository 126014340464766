// src/utils/dateExtensions.ts

// Extend the global Array interface to include containsDates for arrays of Date.
declare global {
    interface Array<T> {
        containsDates(this: Date[], dates: Date[]): boolean;
    }
}

// Add the method to Array.prototype if it doesn't already exist.
if (!Array.prototype.containsDates) {
    Array.prototype.containsDates = function (this: Date[], dates: Date[]): boolean {
        // Check if any date in the provided 'dates' array is in the calling array.
        return dates.some(date =>
            this.some(d => d.toDateString() === date.toDateString())
        );
    };
}

// Export an empty object to convert this file into a module.
export {};
